<template>
    <div>
        <table class="table table__fixed page__one" cellspacing="0" height="300" width="100%">
            <tr width="100%">
                <td width="100%">
                    <img src="@/assets/images/energiewonen/pdf-heading-01.jpg" alt="Afbeelding zon">
                </td>
            </tr>
        </table>
        <table style="width: 85%" class="center table">
            <tr>
                <td>
            <tr><td colspan="2" height="35"></td></tr>
            <tr>
                <td width="100%" style="line-height: 1.5;">
                    <LeadAddressInformation />
                </td>
                <td>
                    <img style="width: 290px;" src="@/assets/images/energiewonen/logo-ew.png" alt="Energie Wonen - Perfectie in zonnepanelen">
                </td>
            </tr>
            <tr><td colspan="2" height="55"></td></tr>
            <tr>
                <td colspan="2">
                    <table style="width: 100%;"  class="table">
                        <tr>
                            <td>
                                <p>Almere, {{ sendDate }}</p>
                            </td>
                        </tr>
                        <tr><td colspan="2" height="40"></td></tr>
                        <tr>
                            <td>
                                <p>Geachte {{ lead.letter_salutation }}</p>
                            </td>
                        </tr>
                        <tr><td colspan="2" height="15"></td></tr>
                        <tr >
                            <td>
                                <p>
                                Naar aanleiding van het prettige gesprek dat ik op {{ appointmentDate }} met u heb gehad over de aanschaf en
                                installatie van zonnepanelen, doe ik u hierbij onze aanbieding toekomen.
                                </p>
                            </td>
                        </tr>
                        <tr><td colspan="2" height="15"></td></tr>
                        <tr v-if="quotationText === null">
                            <td>
                                <p>
                                    Een investering in zonnepanelen is, naast een investering die het milieu ten goede komt, ook een investering voor de lange termijn. Het voorstel van EnergieWonen bestaat uit duurzame materialen en is zo samengesteld dat het systeem vele jaren de maximale opbrengst zal bieden.
                                </p>
                                <br />
                                <p>
                                    Tijdens ons inventarisatiegesprek hebben wij uw mogelijkheden samen bekeken en op basis daarvan heb ik mijn advies samengesteld. Wat met u is afgesproken is in ons advies opgenomen en die afspraak staat. U ontvangt hierdoor na de installatie geen factuur voor onverwacht meerwerk.
                                </p>
                                <br />
                                <p>
                                    Als u een systeem wenst dat zonder zorgen energie oplevert, kies dan voor EnergieWonen. Maandelijks plaatsen we, naar volle tevredenheid, meer dan 550 installaties. Wilt u meer weten over de ervaringen van onze klanten, kijk dan op <a href="https://www.klantenvertellen.nl/reviews/1038668/energiewonen" target="_blank">klantenvertellen.nl</a>.
                                </p>
                                <br />
                                <p>
                                    Met ruim 11 jaar ervaring in het verkopen en installeren van zonnepanelen en meer dan 25.000 installaties, staat kiezen voor EnergieWonen garant voor kwaliteit, zekerheid en zorgeloze energie van de zon. Het begint voor u tijdens de installatie, waarvoor ik een volledige werkinstructie incl. foto’s maak om deze als een zonnetje te laten verlopen, precies zoals u het wilt: zonder verrassingen.
                                </p>
                                <br />
                                <p>
                                    Bijgaand treft u de aanbieding(en), alsmede de technische documentatie omtrent de geadviseerde zonnepanelen installatie aan. Graag verneem ik uw reactie.
                                </p>
                            </td>
                        </tr>
                        <tr v-else>
                            <td v-html="quotationText">
                            </td>
                        </tr>
                        <tr><td colspan="2" height="28"></td></tr>
                        <tr >
                            <td>
                                <p>
                                    Met vriendelijke groet,
                                </p>
                            </td>
                        </tr>
                        <tr><td colspan="2" height="15"></td></tr>
                        <tr >
                            <td>
                                <p>
                                    <strong>{{ adviser.full_name }}</strong>
                                </p>
                            </td>
                        </tr>
                        <tr><td colspan="2" height="15"></td></tr>
                        <tr>
                            <td>
                        <tr>
                            <td>
                                <p>Adviseur zonnepanelen</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>{{ adviser.phone_number }}</p>
                            </td>
                        </tr>
                        </td>
                        </tr>
                    </table>
                </td>
            </tr>
            </td>
            </tr>
        </table>
        <table style="width: 85%" class="table center">
            <tr><td height="70"></td></tr>
            <tr class="template__two__footer">
                <td style="vertical-align:bottom;">
                    <table class="table table__fixed">
                        <tr>
                            <td width="76%" style="vertical-align:bottom;">
                                <img style="width: 350px;  vertical-align:bottom;" src="@/assets/images/energiewonen/footer-logos.png" alt="Check">
                            </td>
                            <td>
                                <img style="height: 70px; width: auto;" src="@/assets/images/energiewonen/rating-small.png" alt="Beoordeling Klein">
                            </td>
                            <td class="ew__adress" style="font-size:10px; text-align:right;">
                                Damsluisweg 48<br />
                                1332 ED Almere<br />
                                Tel: 088 556 6770<br />
                                energiewonen.nl<br />
                                info@energiewonen.nl<br />
                                KvK nr. 34288562<br />
                                BTW nr. NL818.766.566.B01
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import LeadAddressInformation from '@/components/quotation/v1/pages/partials/leadAddressInformation'
import { mapGetters } from 'vuex'

export default {
    components: {
        LeadAddressInformation
    },

    data () {
        return {}
    },

    computed: {
        ...mapGetters('quotation', [
            'lead',
            'adviser',
            'quotation'
        ]),

        appointmentDate () {
            return this.quotation.appointment_date
        },

        sendDate () {
            return this.quotation.send_date
        },

        quotationText () {
            return this.quotation.intro_text
        }
    }
}
</script>

<style scoped>

</style>
