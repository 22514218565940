<template>
    <div class="bg-white shadow">
        <slot />
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
