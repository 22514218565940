<template>
    <div class="bg-gray-200 border border-gray-400 overflow-y-auto overflow-x-hidden rounded-lg p-1" style="height: 85vh">
        <component :is="quotationVersion" :quotation="quotation" />
    </div>
</template>

<script>
import ZipCodeCheck from '@/components/quotation/zipCodeCheck'
import QuotationV1 from '@/components/quotation/v1/quotation'
import { mapGetters } from 'vuex'

export default {
    components: {
        ZipCodeCheck,
        QuotationV1
    },

    data () {
        return {}
    },

    computed: {
        ...mapGetters('quotation', [
            'quotation'
        ]),

        quotationVersion () {
            return 'QuotationV1'
        }
    },

    methods: {
        updateQuotation (quotation) {
            this.quotation = quotation
        }
    }
}
</script>

<style scoped>

</style>
