<template>
    <div class="space-y-4">
        <QuotationPage>
            <IntroPage/>
        </QuotationPage>

        <div class="page-break"></div>

        <div v-for="edition in editions" :key="'products-' + edition.name">
            <QuotationPage>
                <EditionProductPage :edition="edition"/>
            </QuotationPage>

            <div class="page-break"></div>
        </div>

        <div v-for="edition in editions" :key="'roof-' + edition.name">
            <QuotationPage>
                <EditionRoofPage :edition="edition"/>
            </QuotationPage>

            <div class="page-break"></div>
        </div>

        <QuotationPage>
            <WarrantyPage/>
        </QuotationPage>

        <div class="page-break"></div>

        <QuotationPage>
            <ProcessPage/>
        </QuotationPage>

        <div class="page-break"></div>

        <QuotationPage>
            <BackPage/>
        </QuotationPage>
    </div>
</template>

<script>
import IntroPage from '@/components/quotation/v1/pages/introPage'
import EditionProductPage from '@/components/quotation/v1/pages/editionProductPage'
import EditionRoofPage from '@/components/quotation/v1/pages/editionRoofPage'
import WarrantyPage from '@/components/quotation/v1/pages/warrantyPage'
import ProcessPage from '@/components/quotation/v1/pages/processPage'
import BackPage from '@/components/quotation/v1/pages/backPage'
import { mapGetters } from 'vuex'
import QuotationPage from '@/components/quotationPage'

export default {
    components: {
        QuotationPage,
        IntroPage,
        EditionProductPage,
        EditionRoofPage,
        WarrantyPage,
        ProcessPage,
        BackPage
    },

    computed: {
        ...mapGetters('quotation', [
            'editions'
        ])
    }
}
</script>

<style>
@page {
    size: 818px 1150px;
}

html, body {
    font-family: 'sans-serif';
    margin: 0;
    padding: 0;
}

.vertical__text {
    position: absolute;
    padding-top: 30px;
    padding-left: 40px;
    background-color: #eaf2db;
    left: -135px;
    bottom: 55px;
    transform: rotate(270deg);
    font-size: 9px !important; /* Gets overwritten by the main size */
    font-weight: 100;
}

.align__bottom {
    vertical-align: bottom;
}

.page__one p {
    font-size: 13px;
}

.smaller,
.smaller td,
.smaller span,
.smaller p,
.smaller ul li {
    font-size: 12.5px;
}

p,
td {
    font-weight: 300;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

.page-break {
    page-break-before: always;
}

.table {
    width: 100%;
    padding: 0;
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;
    page-break-inside: auto;
}

.table.center {
    margin-left: auto;
    margin-right: auto;
}

.bigger p {
    font-weight: 900;
    font-size: 15px;
}

.table__fixed {
    table-layout: fixed;
}

table td {
    padding: 0;
    vertical-align: top;
}

td {
    text-align: left;
}

th {
    text-align: left;
}

.pb-15 {
    padding-bottom: 16px;
}

.text__right {
    text-align: right;
}

.text__left {
    text-align: left;
}

.invoice td,
.invoice th {
    font-size: 12.5px;
}

.invoice tr td:first-child,
.invoice tr th:first-child {
    padding-right: 15px;
    text-align: right;
}

.invoice tr td:nth-child(4),
.invoice tr th:nth-child(4),
.invoice tr td:nth-child(4) strong,
.invoice tr td:nth-child(3),
.invoice tr th:nth-child(3),
.invoice tr td:nth-child(3) strong {
    text-align: right;
}

.heading td span {
    display: block;
    color: blue;
    font-size: 24px;
    padding-right: 10px;
}

.heading td:last-child {
    border-bottom: 1px solid green;
}

.input td:last-child {
    border-bottom: 1px dotted gray;
}

.input td:first-child {
    padding-right: 10px;
}

.main {
    color: #006888;
}

img {
    max-width: none;
    width: 100%;
}

.green__border {
    border-bottom: 1px dotted #8abf00;
}

.green__border__top {
    border-top: 1px dotted #8abf00;
}

/** Page 2 */
.page__two__sidebar {
    position: relative;
    font-size: 11px;
    background-color: #eaf2db;
    padding-left: 65px;
    padding-right: 20px;
    padding-top: 40px;
}

.sidebar__item td {
    padding: 4px 0 4px 0;
}

.sidebar__item td p {
    font-size: 12px;
    line-height: 1.5;
}

.sidebar__item td img {
    width: 28px;
}

.sidebar__icon {
    width: 24%;
}

h2,
.roofplan__quotation h2.heading {
    color: #006888;
    font-size: 18px;
    font-weight: 300;
}

.requirements__list {
    width: 100%;
    padding-right: 25px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.requirements__list li {
    position: relative;
    left: 10px;
}

.requirements__list li:before {
    position: absolute;
    content: '-';
    left: -10px;
    color: rgb(138, 191, 0);
}

img.builder{
    position: absolute;
    bottom: 0;
    left: 9px;
    width: 280px;
}

.checklist {
    list-style: none;
    margin: 0;
    padding: 0;
}

.checklist li {
    position: relative;
    padding: 0 0 3px;
}

.checklist li img {
    height: 14px;
    width: auto;
    vertical-align: middle;
    padding: 3px 8px 0 0;
}

.address {
    padding: 0;
    margin: 0;
    list-style: none;
}

.address li {
    display: inline-block;
    width: 50%;
    vertical-align: top;
}

.template__two__footer td {
    vertical-align: bottom;
}

/* Page 4/5 */
.roofplan__quotation h2 {
    font-size: 30px;
    font-weight: 900;
    margin: 0;
}

.roofplan__example {
    margin: 0;
    padding: 0;
    width: 50%;
    text-align: center;
}

.roofplan__example img {
    padding: 0;
    margin: 0;
    height: 100%;
    width: auto;
    vertical-align: bottom;
}

.divider {
    border-bottom: 1px solid #8abf00;
    padding: 20px 0;
    /* display: block; */
}

.yield__amount {
    background-color: #8abf00;
    border: 1px solid #8abf00;
}

.yield__amount tr td {
    border: 1px solid #8abf00;
}

.yield__amount tr th {
    color: white;
}

.yield__amount tr td {
    background-color: white;
    padding: 2px 0;
}

.yield__amount tr td,
.yield__amount tr th {
    text-align: center;
}

/* Page 6 */

.guarantee__page h4 {
    color: #006888;
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 16px;
}

.guarantee__page p {
    margin-bottom: 5px;
    font-size: 12.5px;
}

.guarantee__page ul {
    list-style: none;
    padding: 0;
    margin: 0 0 5px 0;
}

.guarantee__page ul li {
    font-size: 12.5px;
}

.guarantee__page ul li:before {
    content: '-';
    color: green;
    margin-right: 5px;
}

.guarantee__page small {
    margin: 0;
    padding: 0;
    font-size: 9px;
}

.narrow {
    padding-right: 80px;
}

/* Page 7 */
.process__content h5 {
    font-size: 16px;
    padding: 0;
    margin: 0;
}

.process__block {
    padding: 0;
    margin: 0;
    border-top: 2px solid #8abf00;
    border-bottom: 2px solid #8abf00;
    background-color: #eef4df;
}

.process__block img {
    height: 40px;
    width: auto;
}

.process__number {
    font-weight: 900;
    font-size: 40px;
    color: #96be3e;
    padding-top: 10px;
}

.process__arrow {
    text-align: center;
}

.process__arrow img {
    width: 12px;
}

/* Back Cover */
.back__cover img {
    max-height: 100%;
    width: auto;
}

/** Extra edits, make sure DOMpdf doesn't apply these when compiling */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

strong {
    font-weight: bold;
}

.ew__adress {
    width: 15%;
}

.main__table {
    height: 1150px;
}

.page__two {
    overflow: hidden;
    height: 1150px;
}

.page__two__sidebar {
    width: 250px;
    padding-right: 30px;
}

.page__two__heading h2 {
    margin-top: 30px;
}

.page__two__sidebar > .table.table__fixed {
    table-layout: unset !important;
}

.page__two__sidebar .solar__panel.one {
    height: 140px;
}

.page__two__sidebar .solar__panel.two {
    height: 140px;
}

.page__two__sidebar .solar__panel img {
    max-height: 100%;
    width: auto;
}

.page__two__sidebar .builder img {
    top: 15px;
    left: -80px;
}

.table.invoice {
    width: 89%;
    margin-bottom: 15px;
}

.table.invoice th:nth-child(3) {
    width: 35%;
}

.under__invoice p {
    margin-bottom: 45px;
}

.payment__requirements p {
    margin-bottom: 50px;
}

.requirements ul {
    margin-bottom: 65px;
}

.page__two h2 {
    margin-bottom: 15px;
}

.roofplan__quotation h2.heading {
    margin: 20px 0;
}

.checklist li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.template__two__footer .image__one {
    width: 145px;
}

.template__two__footer .image__two {
    width: 250px !important;
}

.roofplan__image > td {
    height: 310px;
}

.roofplan__image img {
    max-height: 100%;
    width: auto;
}

.table.yield__amount {
    margin: 10px 0;
}

.page__six__header {
    height: 200px;
}

.process__block table tr td:first-child {
    padding-left: 10px;
}

.process__block table tr td {
    padding: 10px 0;
    padding-left: 10px;
}

.process__arrow img {
    display: block;
    margin: 0 auto;
}

.back__cover {
    overflow: hidden;
}

.vertical__text {
    bottom: 100px;
    z-index: 999;
}

.template__two__footer .image__two {
    width: 275px;
    padding-right: 20px;
}

.template__two__footer td {
    padding-bottom: 20px;
}

/** footer class on every page */
.footer {
    position: fixed;
    right: 0px;
}

.page__one .footer {
    bottom: 125px;
    right: 60px;
}

.page__two .footer,
.page_three .footer,
.page__four .footer,
.page__seven .footer {
    bottom: 50px;
    right: 60px;
}

.page__six .footer {
    width: 90%;
    bottom: 50px;
    right: 60px;
}

</style>
