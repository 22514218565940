<template>
    <table style="width: 85%" class="center table roofplan__quotation main__table">
        <tr>
            <td>
        <tr>
            <td height="95" style="vertical-align: bottom;">
                <h2>Dakplan offerte {{ numberOfEditions > 1 ? edition.name : '' }}</h2>
            </td>
        </tr>
        <tr>
            <td height="25" class="smaller align__bottom">
                <span>{{ panelInformation }}</span>
            </td>
        </tr>
        <tr>
            <td height="15"></td>
        </tr>
        <tr>
            <td>
                <table class="table">
                    <tr>
                        <td style="padding-right: 20px; width:20%;">
                            <table class="table">
                                <tr>
                                    <td style="padding: 10px 0 10px 0" width="100%" class="green__border smaller">
                                        Hierbij uw persoonlijke dakplan
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 10px 0 10px 0" width="100%" class="green__border smaller">
                                        <strong>Leveringsadres:</strong>
                                        <LeadAddressInformation/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 15px 0 14px 0" class="green__border">
                                        <table class="table">
                                            <tr>
                                                <td width="25%">
                                                    <img style="width:30px" src="@/assets/images/energiewonen/dak.png"
                                                         alt="Zonnepaneel Icoon">
                                                </td>
                                                <td class="smaller" style="padding-bottom: 5px;">
                                                    <span><strong>Soort dak:</strong></span> <br/>
                                                    <span>{{ roofInformation.type }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="25%">
                                                    <img style="width:30px"
                                                         src="@/assets/images/energiewonen/dakhelling.png"
                                                         alt="Zonnepaneel Icoon">
                                                </td>
                                                <td class="smaller" style="padding-bottom: 5px;">
                                                    <span><strong>Dakhelling:</strong></span> <br/>
                                                    <span>{{ roofInformation.angle }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="25%">
                                                    <img style="width:30px"
                                                         src="@/assets/images/energiewonen/orientatie.png"
                                                         alt="Zonnepaneel Icoon">
                                                </td>
                                                <td class="smaller" style="padding-bottom: 5px;">
                                                    <span><strong>Oriëntatie:</strong></span> <br/>
                                                    <span>{{ roofInformation.orientation }}</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td height="235" class="roofplan__example">
                            <table class="table table__fixed">
                                <tr class="roofplan__image" style="width: 100%;">
                                    <td v-if="edition.roof_image" style="width: 100%;text-align: center;">
                                        <img v-if="edition.roof_image" :src="edition.roof_image"
                                             alt="Dakplan Voorbeeld">
                                    </td>
                                    <td v-else style="width: 100%;">
                                        <img alt="Dakplan Voorbeeld" src="@/assets/images/energiewonen/leg-plan-voorbeeld.jpeg">
                                        <i class="smaller">Dit is een voorbeeld, vraag uw adviseur naar uw persoonlijke legplan</i>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="margin-top: 10px; margin-bottom:20px; padding-top: 0px;" class="divider">
                            &nbsp;
                        </td>
                    </tr>
                </table>

                <table class="table table__fixed" style="width:100%;">
                    <tr>
                        <td height="35">
                            <h2 class="heading">Verwachte opbrengst van uw systeem (in kWh)</h2>
                        </td>
                    </tr>
                    <tr>
                        <td>
                    <tr class="yield__paragraph">
                        <td height="50">
                            <p class="smaller">
                                De zonnepaneleninstallatie uit offerte {{ edition.name }} heeft een verwachte
                                terugverdientijd van {{ edition.payback_time }} jaar, daarna gaat u ieder jaar winst
                                maken met uw systeem. De totale opwek van dit systeem is ingeschat op {{ edition.energy_yield.total }} kWh.
                                Na 25 jaar heeft dit systeem u ca. €{{ edition.total_profit }},- opgeleverd,
                                na aftrek van uw eigen investering. Dit is uiteraard afhankelijk van veel factoren zoals het
                                aantal zonuren, de energieprijzen en eventuele schaduweffecten op uw panelen. De opwek van uw systeem verschilt per maand.
                                In de grafiek ziet u de verdeling van de opwek over het jaar.
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td height="20"></td>
                    </tr>
                    <tr style="width:100%;">
                        <td style="width:100%;">
                            <img style="max-height: 400px; max-width: 100%; width: auto"
                                 src="@/assets/images/energiewonen/grafiek_opwek_per_maand.png"
                                 alt="Dakplan Voorbeeld">
                        </td>
                    </tr>
                    <tr>
                        <td height="1"></td>
                    </tr>
                    </td>
                    </tr>
                </table>
                <table class="table">
                    <tr>
                        <td height="100"></td>
                    </tr>
                    <tr class="template__two__footer">
                        <td>
                            <table class="table table__fixed">
                                <tr>
                                    <td><img class="image__one" style="width: 155px"
                                             src="@/assets/images/energiewonen/logo-ew-new.png" alt="Check"></td>
                                    <td><img class="image__two" style="width: 350px !important; float:right;"
                                             src="@/assets/images/energiewonen/footer-logos.png" alt="Check"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        </td>
        </tr>
    </table>
</template>

<script>
import LeadAddressInformation from '@/components/quotation/v1/pages/partials/leadAddressInformation'
import { mapGetters } from 'vuex'

export default {
    components: {
        LeadAddressInformation
    },

    props: {
        edition: {
            required: true,
            type: Object
        }
    },

    computed: {
        ...mapGetters('quotation', [
            'roofInformation',
            'editionCount'
        ]),

        panelInformation () {
            return Object.values(this.edition.products.panel).map((panel) => {
                return panel.amount + 'x ' + panel.name
            }).join(', ')
        },

        totalYield () {
            return this.edition.energy_yield.total
        },

        yieldYearOne () {
            return this.edition.energy_yield.yearly['1']
        },

        yieldYearFive () {
            return this.edition.energy_yield.yearly['5']
        },

        yieldYearTen () {
            return this.edition.energy_yield.yearly['10']
        },

        yieldYearFifteen () {
            return this.edition.energy_yield.yearly['15']
        },

        yieldYearTwenty () {
            return this.edition.energy_yield.yearly['20']
        },

        yieldYearTwentyFive () {
            return this.edition.energy_yield.yearly['25']
        },

        numberOfEditions () {
            return this.editionCount
        }
    }
}
</script>

<style scoped>

</style>
