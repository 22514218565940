<template>
    <p>
        {{ lead.full_name }} <br/>
        {{ lead.address }}<br/>
        {{ lead.zip_code }} {{ lead.city }}
    </p>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('quotation', [
            'lead'
        ])
    }
}
</script>

<style scoped>

</style>
