<template>
    <table style="width: 85%" class="center table tabe__fixed main__table roofplan__quotation page__seven">
        <tr>
            <td>
        <tr>
            <td height="95" class="align__bottom narrow">
                <h2>Hoe verloopt het proces?</h2>
            </td>
        </tr>
        <tr>
            <td height="55" class="smaller align__bottom narrow">
                <p>
                    Om ervoor te zorgen dat u weet waar u aan toe bent, nemen wij u stap voor stap mee in het proces van advies tot oplevering. Als u in het proces nog onduidelijkheden ondervindt en vragen heeft, kunt u altijd contact opnemen met uw persoonlijke adviseur.
                </p>
            </td>
        </tr>
        <tr><td height="25"></td></tr>
        <tr>
            <td class="narrow">
                <table class="table table__fixed process__content">
                    <tr>
                        <td width="8%" class="process__number">
                            <span>1</span>
                        </td>
                        <td class="process__block smaller">
                            <table class="table" style="padding: 15px;">
                                <tr>
                                    <td width="8%">
                                        <img src="@/assets/images/energiewonen/advies.png" alt="Advies Icoon">
                                    </td>
                                    <td style="position:relative; overflow:visible;" >
                                        <h5>Een advies op maat</h5>
                                        <p>Onze adviseur komt bij u langs en zorgt ervoor dat u een advies op maat krijgt. Dit advies is volledig afgestemd op uw wensen en de mogelijkheden die er voor u zijn. Voor een goed inzicht kunnen we een vergelijking maken tussen verschillende zonne-systemen. Hierdoor weet u precies waar u voor kiest! Of u nu kiest voor de snelste terugverdientijd of de langste garantie.</p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            &nbsp;
                        </td>
                        <td class="process__arrow">
                            <img src="@/assets/images/energiewonen/arrow-down.png" alt="Pijltje Naar Beneden">
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr><td height="2"></td></tr>
        <tr>
            <td class="narrow">
                <table class="table table__fixed process__content" style="position:relative;">
                    <tr>
                        <td width="8%" class="process__number">
                            <span>2</span>
                        </td>
                        <td class="process__block smaller">
                            <table class="table" style="padding: 15px;">
                                <tr>
                                    <td width="8%">
                                        <img src="@/assets/images/energiewonen/besluit.png" alt="Advies Icoon">
                                    </td>
                                    <td style="position:relative; overflow:visible;" >
                                        <h5>Uw besluit over de offerte</h5>
                                        <p>Het accepteren van onze offerte kan eenvoudig en op elk moment digitaal via onze online portal of gewoon op papier. Uw adviseur kan u op beide manieren bedienen en assisteren.
                                            Na het akkoord ontvangt u binnen 2 werkdagen digitaal de bevestiging en de aanbetalingsnota.</p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            &nbsp;
                        </td>
                        <td class="process__arrow">
                            <img src="@/assets/images/energiewonen/arrow-down.png" alt="Pijltje Naar Beneden">
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr><td height="2"></td></tr>
        <tr>
            <td class="narrow">
                <table class="table table__fixed process__content">
                    <tr>
                        <td width="8%" class="process__number">
                            <span>3</span>
                        </td>
                        <td class="process__block smaller">
                            <table class="table" style="padding: 15px;">
                                <tr>
                                    <td width="8%">
                                        <img src="@/assets/images/energiewonen/installatie.png" alt="Advies Icoon">
                                    </td>
                                    <td style="position:relative; overflow:visible;" >
                                        <h5>De installatie planning</h5>
                                        <p>Na acceptatie van de offerte melden wij het zonne-systeem aan bij het garantiefonds. Onze adviseur stelt de werkbrief voor de installatie op. Indien er nog onduidelijkheden zijn volgt er nog een technische schouw bij u thuis. De werkbrief wordt door een technische specialist gecontroleerd en geakkoordeerd. Één van onze planningsmedewerkers gaat vervolgens aan de slag om de installatie in te plannen. U krijgt per e-mail een voorstel voor een installatiedatum. Deze kunt u via een link in de e-mail bevestigen. Op dat moment staat de installatie gepland!</p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            &nbsp;
                        </td>
                        <td class="process__arrow">
                            <img src="@/assets/images/energiewonen/arrow-down.png" alt="Pijltje Naar Beneden">
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr><td height="2"></td></tr>
        <tr>
            <td class="narrow">
                <table class="table table__fixed process__content">
                    <tr>
                        <td width="8%" class="process__number">
                            <span>4</span>
                        </td>
                        <td class="process__block smaller">
                            <table class="table" style="position:relative; padding: 15px;">
                                <tr>
                                    <td width="8%">
                                        <img src="@/assets/images/energiewonen/wrench.png" alt="Advies Icoon">
                                    </td>
                                    <td style="position:relative; overflow:visible;" >
                                        <h5>Installatie van uw zonnesysteem</h5>
                                        <p>Op de dag van de installatie komt een van onze installatieteams met alle materialen en producten naar u toe. Onze monteurs zijn zeer ervaren en beschikken over de vereiste certificeringen zoals VCA. De benodigde tijd voor de installatie hangt af van de grootte en complexiteit van de installatie. De meeste installaties ronden wij af in een dagdeel.
                                            U ontvangt voorafgaand aan de installatie van ons een e-mail met een uitgebreide toelichting hoe u zich kunt voorbereiden op de installatie, zodat ook u goed voorbereid bent.</p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            &nbsp;
                        </td>
                        <td class="process__arrow">
                            <img src="@/assets/images/energiewonen/arrow-down.png" alt="Pijltje Naar Beneden">
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr><td height="2"></td></tr>
        <tr>
            <td class="narrow">
                <table class="table table__fixed process__content">
                    <tr>
                        <td width="8%" class="process__number">
                            <span>5</span>
                        </td>
                        <td class="process__block smaller">
                            <table class="table" style="position:relative; padding: 15px;">
                                <tr>
                                    <td width="8%">
                                        <img src="@/assets/images/energiewonen/oplevering.png" alt="Advies Icoon">
                                    </td>
                                    <td style="position:relative; overflow:visible;" >
                                        <h5>Oplevering</h5>
                                        <p>Zodra alles is aangesloten en het systeem werkt, zullen de monteurs de installatie met u doorlopen en toelichten waar nodig. U ontvangt ook een gebruikershandleiding als naslagwerk met aanvullende toelichting en de meest gestelde vragen.</p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        </td>
        </tr>
        <tr><td height="45"></td></tr>
        <tr class="template__two__footer">
            <td>
                <table class="table table__fixed">
                    <tr>
                        <td><img class="image__one" style="width: 155px" src="@/assets/images/energiewonen/logo-ew-new.png" alt="Check"></td>
                        <td><img class="image__two" style="width: 350px !important; float:right;" src="@/assets/images/energiewonen/footer-logos.png" alt="Check"></td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
