import { render, staticRenderFns } from "./editionRoofPage.vue?vue&type=template&id=59dc8914&scoped=true&"
import script from "./editionRoofPage.vue?vue&type=script&lang=js&"
export * from "./editionRoofPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59dc8914",
  null
  
)

export default component.exports