<template>
    <div>
        <table style="table-layout: fixed;" class="table page__six__header" cellspacing="0" height="300" width="100%">
            <tr width="100%">
                <td width="100%">
                    <img src="@/assets/images/energiewonen/pdf-heading-01.jpg" alt="Afbeelding zon">
                </td>
            </tr>
        </table>
        <table style="width: 85%" class="center table guarantee__page">
            <tr>
                <td style="padding-top: 25px; padding-right: 25px;">
                    <h4>Kwaliteit en garantie</h4>
                    <p>EnergieWonen heeft in samenspraak met u panelen en een systeem gekozen die het beste aansluiten bij uw wensen aangaande het vermogen, de opbrengst en het uiterlijk van de installatie. De kwaliteitseisen die EnergieWonen stelt aan de leveranciers van haar producten zijn ongekend hoog en dragen bij aan jarenlange probleemloze opbrengst van uw zonnestroomsysteem. EnergieWonen werkt met panelen die allemaal een rendementsgarantie hebben van 25 jaar, zodat een goed werkende panelen voor een lange termijn gegarandeerd zijn.</p>
                    <h4>Keurmerk en certificering</h4>
                    <p>Kwalitatief goed en veilig werken zijn essentieel voor het leveren van een goed werkend systeem. EnergieWonen heeft verschillende certificeringen en keurmerken die een veilige en goede installatie waarborgen. Zo is EnergieWonen lid van Holland Solar, heeft het keurmerk Zonnekeur en is door InstallQ erkend als vakbekwaam installatiebedrijf.</p>
                    <h4>Stichting Garantiefonds ZonneEnergie:</h4>
                    <p>EnergieWonen is, voor particulieren, aangesloten bij de Stichting Garantiefonds ZonneEnergie. Uw aanbetaling en productgarantie zijn hiermee gegarandeerd. Direct na acceptatie van uw offerte verzorgt EnergieWonen de aanmelding van uw systeem. U wordt hierover door ons geïnformeerd en kunt dan via <a href="https://www.sgze.nl" target="_blank">www.sgze.nl</a> uw garantiecertificaat downloaden. U kunt op deze website ook alle voordelen en voorwaarden van het garantiefonds nalezen.</p>
                    <h4>Installatie</h4>
                    <p>
                        Samen hebben we de plaatsing van (en verbindingen tussen) de diverse onderdelen besproken en voor de installatie zullen wij alles uitwerken in een werkbrief en dit met onze installateurs doornemen. Hierdoor zal uw systeem gemonteerd worden naar uw wensen en zijn er achteraf geen verrassingen.
                    </p>
                    <h4>BTW-teruggave</h4>
                    <p>Vanuit het principe <i>schoenmaker blijf bij je leest</i>, verzorgen wij zelf geen BTW-teruggave service. Hiervoor hebben wij een gespecialiseerde partner, ZonnepanelenBTW.nl, die dit voor u kan verzorgen. Wij hebben met hen gereduceerde tarieven voor u afgesproken, zodat u toch kunt profiteren van onze goede afspraken. Hiervoor ontvangt u van ons via de e-mail aanvullende informatie.
                        De belastingdienst stelt strikte voorwaarden aan de BTW-teruggave. Uw adviseur kan u hierover informeren of u kunt kijken op onze website voor meer informatie.</p>
                    <h4>Financiering</h4>
                    <p>Indien u een financiering voor uw aanschaf wenst aan te vragen dan kunnen wij u, op uw verzoek, in contact brengen met onze financiële partner. U kunt dan gebruik maken van de scherpte tarieven die wij met hen hebben afgesproken.
                        U ontvang dan van hen een digitaal aanvraagformulier.</p>
                    <h4>Tevreden klanten</h4>
                    <img style="height: 70px; width: auto; float: right; margin-left: 10px; margin-right: 10px;"
                         src="@/assets/images/energiewonen/rating-small.png"
                         alt="Beoordeling Klein">
                    <p>Een tevreden klant is een voorwaarde om een gezond bedrijf te hebben. Daar geloven wij in, dat is onze missie. Door het leveren van een goed, op maat gemaakt advies, kwalitatief de beste producten en een perfect uitgevoerde installatie, zorgen wij voor tevreden klanten die nog jarenlang plezier hebben van hun investering. Op <a href="https://www.klantenvertellen.nl/reviews/1038668/energiewonen" target="_blank">klantenvertellen.nl</a> kunt u lezen hoe klanten onze dienstverlening ervaren hebben.</p>
                </td>
            </tr>
            <tr>
                <td style="padding: 15px 0 30px 0;">
                </td>
            </tr>
            <tr class="template__two__footer">
                <td>
                    <table class="table table__fixed">
                        <tr>
                            <td><img style="width: 175px" src="@/assets/images/energiewonen/logo-ew-new.png" alt="Check"></td>
                            <td>
                                <img class="image__two" style="width: 350px !important; float:right;" src="@/assets/images/energiewonen/footer-logos.png" alt="Check">
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
