<template>
    <table style="page-break-inside: avoid; height:100%;" class="table table__fixed  back__cover main__table">
        <tr class="template__two__footer">
            <td style="padding-left: -325px;">
                <img width="200" src="@/assets/images/energiewonen/back-cover.jpg" alt="Back Cover">
            </td>
        </tr>
    </table>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
