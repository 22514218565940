<template>
    <table class="table page__two main__table">
        <tr>
            <!-- Sidebar -->
            <td width="130" height="750" class="page__two__sidebar">
                <p class="vertical__text">*Op deze berekening kunnen geen rechten worden ontleend</p>
                <table class="table table__fixed" style="width: 100%;">
                    <tr>
                        <td width="80%">
                            <p class="solar__panel one">
                                <img v-if="panelDetail !== null && panelDetail.image !== null"
                                     :src="panelDetail.image" width="120" height="19%"
                                     alt="Afbeelding Sidebar Boven">
                            </p>
                            <p style="padding-top: 5px; padding-bottom:20px; font-size: 13px;">
                                {{ panelDetail !== null ? panelDetail.name : '' }}
                            </p>
                            <p class="solar__panel two">
                                <img v-if="inverterDetail !== null && inverterDetail.image !== null"
                                    :src="inverterDetail.image" width="120" alt="Afbeelding Sidebar Onder"
                                >
                            </p>
                            <p style="padding-top: 5px; padding-bottom:20px; font-size: 13px;">
                                {{ inverterDetail !== null ? inverterDetail.name : '' }}
                            </p>
                            <div v-if="hasChargingStation && chargingStationDetail !== null">
                                <p class="solar__panel two">
                                    <img v-if="chargingStationDetail.image !== null"
                                         :src="chargingStationDetail.image" width="120" alt="Afbeelding Laadpaal"
                                    >
                                </p>
                                <p style="padding-top: 5px; padding-bottom:20px; font-size: 13px;">
                                    {{ chargingStationDetail.name }}
                                </p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td height="3"></td>
                    </tr>
                </table>

                <table class="table table__fixed" style="width: 85%;">
                    <tr>
                        <td colspan="2" width="150" class="green__border"></td>
                    </tr>
                    <tr>
                        <td colspan="2" height="5"></td>
                    </tr>
                    <tr class="sidebar__item">
                        <td class="sidebar__icon">
                            <img src="@/assets/images/energiewonen/paneel.png" alt="Zonnepaneel Icoon">
                        </td>
                        <td>
                            <p>
                                <strong>Aantal panelen: </strong><br/> {{ edition.panel_information.amount }}
                            </p>
                        </td>
                    </tr>
                    <tr class="sidebar__item">
                        <td class="sidebar__icon">
                            <img style="width:16px; margin-left: 2px; margin-top:3px;"
                                 src="@/assets/images/energiewonen/bliksem.png" alt="Zonnepaneel Icoon">
                        </td>
                        <td>
                            <p>
                                <strong>Vermogen: </strong><br/> {{ edition.panel_information.power }}Wp
                            </p>
                        </td>
                    </tr>
                    <tr class="sidebar__item">
                        <td class="sidebar__icon">
                            <img src="@/assets/images/energiewonen/stekker.png" alt="Zonnepaneel Icoon">
                        </td>
                        <td>
                            <p>
                                <strong>Jaarlijkse opwek: </strong><br/> {{ edition.panel_information.yearly_yield }} kWh
                            </p>
                        </td>
                    </tr>
                    <tr class="sidebar__item">
                        <td class="sidebar__icon">
                            <img src="@/assets/images/energiewonen/kompas.png" alt="Zonnepaneel Icoon">
                        </td>
                        <td>
                            <p>
                                <strong>Vermogen jaar 25: </strong><br/> {{ edition.panel_information.power_percentage_year_25 }}%
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" height="10"></td>
                    </tr>
                    <tr>
                        <td colspan="2" width="150" class="green__border"></td>
                    </tr>
                    <tr>
                        <td colspan="2" height="10"></td>
                    </tr>
                    <tr class="sidebar__item">
                        <td class="sidebar__icon">
                            <img src="@/assets/images/energiewonen/geldzak.png" alt="Zonnepaneel Icoon">
                        </td>
                        <td>
                            <p>
                                <strong>Investering excl. BTW: </strong><br/> &euro;{{ edition.price.total_ex_vat }}
                            </p>
                        </td>
                    </tr>
                    <tr class="sidebar__item">
                        <td class="sidebar__icon">
                            <img src="@/assets/images/energiewonen/geldverdien.png" alt="Zonnepaneel Icoon">
                        </td>
                        <td>
                            <p>
                                <strong>Opbrengst 1e jaar*: </strong><br/> &euro;{{ edition.first_year_price_yield }}
                            </p>
                        </td>
                    </tr>
                    <tr class="sidebar__item">
                        <td class="sidebar__icon">
                            <img src="@/assets/images/energiewonen/geldterug.png" alt="Zonnepaneel Icoon">
                        </td>
                        <td>
                            <p>
                                <strong>Terugverdientijd*: </strong><br/> {{ edition.payback_time }} jaar
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" height="10"></td>
                    </tr>
                    <tr>
                        <td colspan="2" width="150" class="green__border"></td>
                    </tr>
                    <tr>
                        <td colspan="2" height="10"></td>
                    </tr>
                    <tr class="sidebar__item">
                        <td class="sidebar__icon">
                            <img src="@/assets/images/energiewonen/boom.png" alt="Zonnepaneel Icoon">
                        </td>
                        <td>
                            <p>
                                <strong>CO2 besparing: </strong><br/> gelijk aan {{ edition.co2_reduction }} bomen
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" height="10"></td>
                    </tr>
                    <tr>
                        <td colspan="2" width="150" class="green__border"></td>
                    </tr>
                    <img class="builder" v-if="!hasChargingStation" src="@/assets/images/energiewonen/bouwvakker.png" alt="Bouwvakker Zijbar Afbeelding">
                </table>
            </td>
            <!-- /Sidebar -->

            <!-- Content -->
            <td width="40">&nbsp;</td>
            <td class="smaller">
                <table class="table">
                    <tr class="page__two__heading">
                        <td width="260">
                            <img style="width: 290px; margin-right: 85px; margin-top: 50px; float: right;" src="@/assets/images/energiewonen/logo-ew.png" alt="Energie Wonen - Perfectie in zonnepanelen">
                        </td>
                    </tr>
                    <tr class="page__two__heading">
                        <td>
                            <h2>Offerte zonnepanelen {{ numberOfEditions > 1 ? edition.name : '' }}</h2>
                        </td>
                    </tr>
                    <tr>
                        <td width="260">
                            <table class="table invoice">
                                <tr>
                                    <th width="10">Aantal</th>
                                    <th width="240">Omschrijving</th>
                                    <th width="10">&nbsp;</th>
                                    <th width="10">Garantie</th>
                                </tr>
                                <tr v-for="(product, index) in products" :key="'product-' + index">
                                    <td>{{ product.amount }}</td>
                                    <td>{{ product.name }}</td>
                                    <td>&nbsp;</td>
                                    <td><span v-if="product.warranty !== ''">{{ product.warranty }} jaar</span></td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Aanmelding bij SGZE</td>
                                    <td>&nbsp;</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td height="6" colspan="4"></td>
                                </tr>
                                <tr>
                                    <td style="padding-bottom: 5px;"><strong>Prijs</strong></td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr v-if="discountAndManuals.length > 0">
                                    <td>&nbsp;</td>
                                    <td>Subtotaal</td>
                                    <td>&euro;</td>
                                    <td>{{ edition.price.sub_total_ex_vat }}</td>
                                </tr>
                                <tr v-for="(product, index) in discountAndManuals" :key="'discount-' + index">
                                    <td>&nbsp;</td>
                                    <td>{{ product.name }}</td>
                                    <td>&euro;</td>
                                    <td :class="{ 'text-red-500': product.is_discount }">{{ product.price }}</td>
                                </tr>
                                <tr class="total-sum">
                                    <td>&nbsp;</td>
                                    <td>Totaal exclusief 21% BTW</td>
                                    <td>&euro;</td>
                                    <td>{{ edition.price.total_ex_vat }}</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>BTW 21%</td>
                                    <td>&euro;</td>
                                    <td>{{ edition.price.vat }}</td>
                                </tr>
                                <tr class="total-sum">
                                    <td>&nbsp;</td>
                                    <td><strong>Totaal inclusief 21% BTW</strong></td>
                                    <td>&euro;</td>
                                    <td><strong>{{ edition.price.total }}</strong></td>
                                </tr>

                            </table>
                    <tr>
                        <td height="10"></td>
                    </tr>
                    <tr class="under__invoice">
                        <td height="50">
                            <p>
                                De terug te vorderen BTW bedraagt maximaal €{{ edition.price.vat }}. Uw netto
                                investering bedraagt dan: €{{ edition.price.total_ex_vat }} exclusief €{{ edition.price.tax_lump_sum }}
                                belastingforfait.
                            </p>
                        </td>
                    </tr>
                    <tr class="requirements">
                        <td height="120">
                            <h2>Van toepassing zijnde voorwaarden</h2>
                            <ul class="requirements__list">
                                <li>Op deze overeenkomst zijn onze Algemene Voorwaarden van toepassing</li>
                                <li>Deze aanbieding is geldig tot en met {{ edition.due_date }}</li>
                                <li>Klant verklaart nadrukkelijk dat er geen asbest aanwezig is
                                </li>
                                <li>{{ edition.has_vat_refund_service ? 'De BTW-teruggave kunt u laten uitvoeren door ZonnepanelenBTW' : 'EnergieWonen is niet verantwoordelijk voor de aanvraag van de BTW-teruggave of de toekenning ervan' }}</li>
                            </ul>
                        </td>
                    </tr>
                    <tr class="payment__requirements" v-if="displayPaymentConditions">
                        <td height="100">
                            <h2>Betalingsvoorwaarden</h2>
                            <p>
                                {{ edition.price.down_payment }}% bij opdracht, te voldoen via bank na ontvangst
                                digitale nota <br/>
                                {{ edition.price.final_payment }}% {{ paymentTermsText }}
                                <span v-if="generalInformation.funding_type === 'snn'">
                                    <br />Onder voorbehoud van toekenning subsidie SNN
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr class="signature__heading">
                        <td>
                            <h2>Ondertekening voor akkoord</h2>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <table class="table">
                                <tr>
                                    <td width="50%">
                                        {{ lead.full_name }} <br/>
                                        {{ lead.city }} <br/><br/>
                                        <span v-if="signature === null || signature.edition_id !== edition.id">
                                            Datum: <br/>
                                            Handtekening:
                                        </span>
                                            <span v-else>
                                            Datum: {{ signature.accepted_at }}<br/>
                                            Handtekening:
                                            <img v-if="signature.image !== null" :src="signature.image" style="height: 100px; width: 230px;">
                                        </span>
                                    </td>
                                    <td width="50%">
                                        Dhr. D. van Gorkum - Directeur EnergieWonen<br/>
                                        Almere <br/><br/><br/>
                                        Handtekening:
                                        <img src="@/assets/images/energiewonen/handtekening-d-van-gorkum.jpg"
                                             style="height: 100px; width: 230px;">
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td height="60"></td>
                    </tr>
                    <tr>
                        <td class="smaller" colspan="2">
                            <ul class="checklist">
                                <li v-if="generalInformation.subject_to_funding"><img src="@/assets/images/energiewonen/check.png" alt="Check">onder voorbehoud van
                                    financiering
                                </li>
                                <li v-if="generalInformation.subject_to_inspection"><img src="@/assets/images/energiewonen/check.png" alt="Check">onder voorbehoud van
                                    schouw
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td :height="signature !== null && signature.image !== null ? '100' : '200'"></td>
                    </tr>
                    <tr class="template__two__footer">
                        <td>
                            <table class="table table__fixed">
                                <tr>
                                    <td style="width: 47.5%"></td>
                                    <td style=""><img class="image__two" style="width: 350px !important;"
                                             src="@/assets/images/energiewonen/footer-logos.png" alt="Check"></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    </td>
                    </tr>
                </table>
            </td>
            <!-- /Content -->
        </tr>
    </table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        edition: {
            required: true,
            type: Object
        }
    },

    data () {
        return {
            productTypes: [
                'panel',
                'inverter',
                'optimizer',
                'montage',
                'charging_station',
                'other',
                'cable',
                'install_type'
            ],
            discountAndManualTypes: [
                'fuse_box',
                'discount',
                'manual'
            ]
        }
    },

    computed: {
        ...mapGetters('quotation', [
            'lead',
            'signature',
            'generalInformation',
            'editionCount'
        ]),

        products () {
            return this.flattenProductsForTypes(this.productTypes)
        },

        discountAndManuals () {
            return this.flattenProductsForTypes(this.discountAndManualTypes)
        },

        panelDetail () {
            return this.getProductTypeDetail('panel')
        },

        inverterDetail () {
            return this.getProductTypeDetail('inverter')
        },

        hasChargingStation () {
            return this.edition.has_charging_station
        },

        chargingStationDetail () {
            if (!this.hasChargingStation) {
                return null
            }

            return this.getProductTypeDetail('charging_station')
        },

        numberOfEditions () {
            return this.editionCount
        },

        paymentTermsText () {
            if (this.generalInformation.subject_to_funding) {
                return 'direct na oplevering, te voldoen via bank na ontvangst digitale nota'
            }

            return 'direct na aflevering/oplevering, per pinbetaling te voldoen'
        },

        displayPaymentConditions () {
            return this.generalInformation.display_payment_conditions
        }
    },

    methods: {
        flattenProductsForTypes (types) {
            const products = []

            types.forEach((type) => {
                if (!Object.prototype.hasOwnProperty.call(this.edition.products, type)) {
                    return true
                }

                Object.keys(this.edition.products[type]).forEach((productId) => {
                    products.push(this.edition.products[type][productId])
                })
            })

            return products
        },

        getProductTypeDetail (type) {
            const products = Object.keys(this.edition.products[type])

            if (products.length === 0) {
                return null
            }

            const product = this.edition.products[type][products[0]]

            return {
                image: Object.prototype.hasOwnProperty.call(product, 'image') ? product.image : null,
                name: product.name
            }
        }
    }
}
</script>

<style scoped>
    tr.total-sum td:last-child {
        border-top: 1px solid #2c3e50;
    }
</style>
